import en from "@/assets/locales/en.json";
import zhhant from "@/assets/locales/zh-Hant.json";
import zh from "@/assets/locales/zh.json";

export const locales = {
  zh,
  "zh-HANT": zhhant,
  en,
};
export type Locales = keyof typeof locales;
